import React, { useState, useEffect } from 'react';
//import  Alert , { AlertDescription } from '@/components/ui/alert';

const Example = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Configure these values based on your Microsoft App registration
  const config = {
    clientId: 'b11b9455-88ff-4f2f-92fa-0caf915e91d8',
    redirectUri: window.location.origin,
    authority: 'https://login.microsoftonline.com/common',
    scopes: ['user.read']
  };

  // Timeout duration in milliseconds (30 seconds)
  const TIMEOUT_DURATION = 30000;

  const handleLogin = async () => {
    setLoading(true);
    setError(null);

    try {
      // Create URL for OAuth endpoint
      const authUrl = `${config.authority}/oauth2/v2.0/authorize?` +
        `client_id=${config.clientId}` +
        `&response_type=code` +
        `&redirect_uri=${encodeURIComponent(config.redirectUri)}` +
        `&scope=${encodeURIComponent(config.scopes.join(' '))}` +
        `&response_mode=fragment` +
        `&prompt=select_account` +
        `&state=${generateRandomState()}`;

      // Open the OAuth window
      const authWindow = window.open(authUrl, '_blank', 'width=800,height=600');

      // Set up timeout
      const timeoutId = setTimeout(() => {
        if (authWindow) {
          authWindow.close();
        }
        setError('Authentication request timed out. Please try again.');
        setLoading(false);
      }, TIMEOUT_DURATION);

      // Listen for messages from popup window
      window.addEventListener('message', function authListener(event) {
        // Verify origin for security
        if (event.origin !== window.location.origin) return;

        if (event.data.type === 'AUTH_SUCCESS') {
          clearTimeout(timeoutId);
          window.removeEventListener('message', authListener);
          if (authWindow) {
            authWindow.close();
          }
          setIsAuthenticated(true);
          setLoading(false);
        } else if (event.data.type === 'AUTH_ERROR') {
          clearTimeout(timeoutId);
          window.removeEventListener('message', authListener);
          if (authWindow) {
            authWindow.close();
          }
          setError('Authentication failed. Please try again.');
          setLoading(false);
        }
      });
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      setLoading(false);
    }
  };

  // Helper function to generate random state
  const generateRandomState = () => {
    return Math.random().toString(36).substring(7);
  };

  return (
    <div className="p-4">
      {error && (
        alert(error)
        // <Alert variant="destructive" className="mb-4">
        //   <AlertDescription>{error}</AlertDescription>
        // </Alert>
      )}
      
      <button
        onClick={handleLogin}
        disabled={loading}
        className={`px-4 py-2 rounded ${
          loading 
            ? 'bg-gray-300 cursor-not-allowed' 
            : 'bg-blue-600 hover:bg-blue-700 text-white'
        }`}
      >
        {loading ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Signing in...
          </span>
        ) : (
          'Sign in with Microsoft'
        )}
      </button>

      {isAuthenticated && (
        <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
          Successfully authenticated!
        </div>
      )}
    </div>
  );
};

export default Example;