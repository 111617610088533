import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUpload";
import { pdfjs } from "pdfjs-dist";
import Tesseract from "tesseract.js";
import moment from "moment";
import { getDocument } from "pdfjs-dist";
import {
  getIsAuthenticated,
  getStaffId,
  getToken,
  getRoles,
} from "../js/stateUtils";
import { fetchClient } from "../js/fetchClient";
import "../css/AbsenceRequestCopy.css";

const leaveTypes = [
  { value: "annual", label: "Annual Leave" },
  { value: "mc", label: "Medical Leave" },
];

const leaveDurations = [
  { value: "full-day", label: "Full Day" },
  { value: "half-day", label: "Half Day" },
];
const AbsenceRequestCopy = () => {
  const today = new Date().toISOString().split("T")[0];
  //console.log("today :: ",today);
  const [employeeId, setEmployeeId] = useState(getStaffId());
  const [leaveType, setLeaveType] = useState("annual");
  const [leaveDuration, setLeaveDuration] = useState("full-day");
  const [isUrgent, setIsUrgent] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState(today); //2024-06-19");
  const [appliedEndDate, setAppliedEndDate] = useState(today); //2024-06-19");
  const [ocrResult, setOcrResult] = useState("");
  const [dates, setDates] = useState({});
  const [isMCValid, setIsMCValid] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [metadataId, setMetadataId] = useState(""); // Store metadataId
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control the dialog
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setFile(null);
    setUploadedFileName("");
  }, [leaveType]);

  const extractDates = (text) => {
    const datePattern =
      /\b(\d{1,2}(?:st|nd|rd|th)?[-\/\s]?(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?|\d{1,2})[-\/\s]?\d{2,4})\b/gi;
    const matches = text.match(datePattern);
    const dateCounts = {};

    if (matches) {
      matches.forEach((dateStr) => {
        const date = moment(
          dateStr,
          [
            "DD-MM-YYYY",
            "DD/MM/YYYY",
            "DD MMM YYYY",
            "DD MMMM YYYY",
            "MMMM DD, YYYY",
            "MMM DD, YYYY",
            "DD/MM/YY",
            "DD-MM-YY",
          ],
          true
        );

        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          if (dateCounts[formattedDate]) {
            dateCounts[formattedDate] += 1;
          } else {
            dateCounts[formattedDate] = 1;
          }
        }
      });
    }
    return dateCounts;
  };

  const isValid = (dates, startDate, endDate) => {
    if (!dates[startDate] || !dates[endDate]) {
      return false;
    }

    if (startDate === endDate) {
      return dates[startDate] >= 2;
    }

    return dates[startDate] && dates[endDate];
  };

  const handleStartDateChange = (newStartDate) => {
    setAppliedStartDate(newStartDate);
    // console.log(
    //   "appliedEndDate && new Date(appliedEndDate) < new Date(newStartDate) :: ",
    //   appliedEndDate && new Date(appliedEndDate) < new Date(newStartDate)
    // );
    if (appliedEndDate && new Date(appliedEndDate) < new Date(newStartDate)) {
      setAppliedEndDate(newStartDate);
    }
  };

  const handleDropTextract = useCallback(
    async (acceptedFiles) => {
      // console.log("handleDropTextract----------------");
      // console.log("acceptedFiles[0] :: ", acceptedFiles[0]);
      if (acceptedFiles[0] === null) {
        return;
      }
      setIsUploading(true);
      setMessage("Validating Document");
      setMessageType("success");
      if (new Date(appliedStartDate) > new Date(appliedEndDate)) {
        alert("Applied start date must be on or before applied end date");
        return;
      }

      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setUploadedFileName(acceptedFiles[0].name);
      // console.log("Processing file", acceptedFiles[0]);

      const formData = new FormData();
      // console.log("employeeId :: ", employeeId);
      formData.append("employeeId", employeeId);
      formData.append("leaveType", leaveType);
      formData.append("appliedStartDate", appliedStartDate);
      formData.append("appliedEndDate", appliedEndDate);
      formData.append("file", acceptedFiles[0]);
      // call the api here
      await fetchClient(
        `getEmployee`,
        {
          method: "GET",
        },
        { staffId: getStaffId() }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("data :: ", data);
          formData.append("employeeName", data.fullName);
        })
        .catch((error) =>
          console.error("Error fetching additional data:", error)
        );
      // formData.forEach((value, key) => {
      //   console.log(`${key}: ${value}`);
      // });

      // console.log("formData :: ", JSON.stringify(formData));
      try {
        const response = await fetchClient("validateMC", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const data = await response.text(); // Assuming the response is a string
          setMessage(response);
          setMessageType("success");
          setIsMCValid(true);
          // console.log("Success:", data);
        } else {
          const data = await response.text();
          setIsMCValid(false);
          setMessage(
            "The Medical Certificate you have uploaded has failed to validate. Admin may get back to you. " +
              data
          );
          setMessageType("error");
          console.error("HTTP Error:", response.status, response.statusText);
          console.error("Error:", data);
        }
      } catch (error) {
        console.error("Error during login:", error);
      } finally {
        setIsUploading(false);
        // console.log("!isFormValid :: ", !isFormValid);
        // console.log("isUploading :: ", isUploading);
      }
    },
    [employeeId, appliedStartDate, appliedEndDate]
  );

  // const handleDropLocal = useCallback(
  //   async (acceptedFiles) => {
  //     setIsUploading(true);
  //     setMessage("");
  //     setMessageType("");
  //     if (new Date(appliedStartDate) > new Date(appliedEndDate)) {
  //       alert("Applied start date must be on or before applied end date");
  //       return;
  //     }

  //     const selectedFile = acceptedFiles[0];
  //     setFile(selectedFile);
  //     //console.log("Processing file", selectedFile);

  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const buffer = e.target.result;

  //       let images = [];
  //       if (selectedFile.type === "application/pdf") {
  //         const pdf = await getDocument({ data: buffer }).promise;
  //         for (let i = 0; i < pdf.numPages; i++) {
  //           const page = await pdf.getPage(i + 1);
  //           const viewport = page.getViewport({ scale: 2 });
  //           const canvas = document.createElement("canvas");
  //           const context = canvas.getContext("2d");
  //           canvas.height = viewport.height;
  //           canvas.width = viewport.width;

  //           await page.render({ canvasContext: context, viewport }).promise;
  //           const dataUrl = canvas.toDataURL("image/png");
  //           const imgBuffer = await (await fetch(dataUrl)).arrayBuffer();
  //           images.push(new Uint8Array(imgBuffer));
  //         }
  //       } else if (
  //         selectedFile.type === "image/png" ||
  //         selectedFile.type === "image/jpeg"
  //       ) {
  //         images = [buffer];
  //       }

  //       const ocrResults = await Promise.all(
  //         images.map((image) => Tesseract.recognize(image, "eng"))
  //       );
  //       const ocrText = ocrResults.map((result) => result.data.text).join(" ");

  //       const dates = extractDates(ocrText);
  //       const isMCValid = isValid(
  //         dates,
  //         moment(appliedStartDate).format("YYYY-MM-DD"),
  //         moment(appliedEndDate).format("YYYY-MM-DD")
  //       );

  //       // Simulating saving metadata and returning the response
  //       const metadataId = "simulatedMetadataId";

  //       setUploadedFileName(selectedFile.name);
  //       setOcrResult(ocrText);
  //       setDates(dates);
  //       setIsMCValid(isMCValid);
  //       setMetadataId(metadataId);
  //       setIsUploading(false);
  //     };

  //     reader.readAsArrayBuffer(selectedFile);
  //   },
  //   [employeeId, appliedStartDate, appliedEndDate]
  // );

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     if (new Date(appliedStartDate) > new Date(appliedEndDate)) {
  //       alert("Applied start date must be on or before applied end date");
  //       return;
  //     }

  //     const selectedFile = acceptedFiles[0];
  //     setFile(selectedFile);
  //     //console.log("Sending file to backend", selectedFile)

  //     const formData = new FormData();
  //     formData.append("file", selectedFile);
  //     //formData.append("name", employeeName);
  //     formData.append("id", employeeId);
  //     formData.append("appliedStartDate", appliedStartDate);
  //     formData.append("appliedEndDate", appliedEndDate);

  //     setIsUploading(true);

  //     axios
  //       .post("/uploadMC", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         setIsUploading(false);
  //         setUploadedFileName(selectedFile.name);
  //         setOcrResult(response.data.text);
  //         setDates(response.data.dates);
  //         setIsMCValid(response.data.isMCValid);
  //         setMetadataId(response.data.metadataId); // Store metadataId
  //       })
  //       .catch((error) => {
  //         setIsUploading(false);
  //         console.error("Error uploading file:", error);
  //       });
  //   },
  //   [employeeId, appliedStartDate, appliedEndDate]
  // );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    //console.log("Submitting form");
    setMessage("Please wait...");
    setIsSubmitting(true);
    setMessageType("success");
    const formData = new FormData();
    //formData.append("name", employeeName);
    formData.append("staffId", employeeId);
    formData.append("leaveType", leaveType);
    formData.append("appliedStartDate", appliedStartDate);
    formData.append("appliedEndDate", appliedEndDate);
    formData.append("file", file);
    formData.append("uploadedFileName", uploadedFileName);
    //formData.append("ocrResult",ocrResult);
    //formData.append("dates",dates);
    formData.append("isMCValid", isMCValid);
    formData.append("leaveDuration", leaveDuration);
    formData.append("isUrgent", isUrgent);
    formData.append(
      "mcRejectionReason",
      isMCValid
        ? ""
        : message.replace(
            "The Medical Certificate you have uploaded has failed to validate. Admin may get back to you. ",
            ""
          )
    );
    //console.log("formData :: ",formData);
    formData.forEach((value, key) => {
      //console.log(`${key}: ${value}`);
    });
    //console.log("------------", Object.keys(dates).length );
    for (let date in dates) {
      if (dates.hasOwnProperty(date)) {
        //console.log(`${date}: ${dates[date]}`);
      }
    }
    //formData.append("metadataId", metadataId); // Append metadataId; if no MC, will be null

    const response = await fetchClient(
      "submitEmployeeLeave",
      {
        method: "POST",
        body: formData, //JSON.stringify(formData),
      },
      null
    );
    //console.log("response :: ",response);
    const responseData = await response.text();
    // console.log("responseData :: ", responseData);
    if (response.ok) {
      setMessage(responseData);
      setMessageType("success");

      // Wait for 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      // console.log("Waited 5 seconds");

      setFile(null);
      setAppliedStartDate(today);
      setAppliedEndDate(today);
      setLeaveType("annual");
      setUploadedFileName("");
      setOcrResult("");
      setLeaveDuration("full-day");
      setIsUrgent(false);
      setDates({});
      setIsMCValid(false);
    } else {
      // console.log("else----");
      setMessage(responseData);
      setMessageType("error");
    }

    setIsSubmitting(false);
    // setTimeout(() => {
    //   console.log("Waited 5 seconds");
    //   setMessage("");
    //   setMessageType("");
    // }, 5000);

    // axios
    //   .post("/submitAbsenceRequest", formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     console.error("Error submitting form:", error);
    //   });
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmSubmit = () => {
    setIsDialogOpen(false);
    handleSubmit();
  };

  // useEffect(() => {
  //   //console.log("appliedStartDate :: ",appliedStartDate);
  // },[appliedStartDate]);

  useEffect(() => {
    const validateForm = async () => {
      // console.log("calling use effect after change.....");

      setMessage("");
      setMessageType("");
      // console.log("leaveType === mc :: ", leaveType === "medical");
      // console.log("!!file :: ", !!file);
      if (leaveType === "mc" && !!file) {
        // console.log("leaveType === mc && file becomes true");
        handleDropTextract([file]);
      }

      const isFormFilled = !!(
        employeeId &&
        leaveType &&
        appliedStartDate &&
        appliedEndDate
      );
      const isMedicalValid = leaveType === "annual" || !!file;

      // console.log("isMedicalValid :: ", isMedicalValid);
      // console.log("isFormFilled :: ", isFormFilled);

      setIsFormValid(isFormFilled && isMedicalValid);
    };

    validateForm();
  }, [
    //employeeId,
    leaveType,
    appliedStartDate,
    appliedEndDate,
    file,
    //isMCValid,
  ]);

  return (
    <Grid container justifyContent="center" sx={{ padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 4, width: "80%" }}>
        <Typography variant="h5" gutterBottom>
          Absence Request
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleDialogOpen();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Employee ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Leave Type</InputLabel>
                <Select
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  label="Leave Type"
                >
                  {leaveTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={appliedStartDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
                inputProps={{ min: today }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={appliedEndDate}
                onChange={(e) => setAppliedEndDate(e.target.value)}
                inputProps={{ min: appliedStartDate || today }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Leave Duration</InputLabel>
                <Select
                  value={leaveDuration}
                  onChange={(e) => setLeaveDuration(e.target.value)}
                  label="Leave Type"
                >
                  {leaveDurations.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isUrgent}
                      onChange={(e) => setIsUrgent(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Emergency Leave (Check it only if necessary)"
                />
              </FormControl>
            </Grid>
            {leaveType === "mc" && appliedEndDate && appliedStartDate && (
              <Grid item xs={12}>
                <FileUpload
                  onDrop={handleDropTextract}
                  isUploading={isUploading}
                  uploadedFileName={uploadedFileName}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {message && (
                <div className={`message ${messageType}`}>{message}</div>
              )}
            </Grid>
            {/* {console.log("disabled :: ",!isFormValid , !isUploading)} */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isFormValid || isSubmitting || isUploading}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* <Box mt={4}>
          {file && leaveType === "mc" && !isUploading && (
            <>
              {isMCValid ? (
                <div className={`message success`}>
                  {message}
                  {
                    "The Medical Certificate you have uploaded is validated Successfully!"
                  }
                </div>
              ) : (
                <div className={`message error`}>
                  {message}
                  {
                    "The Medical Certificate you have uploaded has failed to validate. Admin may get back to you."
                  }
                </div>
              )}
            </>
          )}
        </Box> */}
      </Paper>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Submission"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AbsenceRequestCopy;
