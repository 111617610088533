import React from "react";
import logo from "../assets/HDB_logo.png"; // Replace with your logo's path

const Header = () => {
  return (
    <header className="flex items-center justify-between px-4 py-2 bg-gray-100 shadow-md">
      {/* Logo Section */}
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-8 w-auto" />
        <span className="pl-2 text-xl font-semibold text-gray-600">HR Management System</span>
      </div>

    </header>
  );
};

export default Header;
