import React, { useState, useEffect } from "react";
import {
  getIsAuthenticated,
  getStaffId,
  getToken,
  getRoles,
} from "../js/stateUtils";
import { fetchClient } from "../js/fetchClient";
import { Grid } from "@mui/material";

const AddUser = () => {
  const [fullName, setFullName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [contractStartDate, setContractStartDate] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");
  const [plName, setPlName] = useState({});
  const [vendorName, setvendorName] = useState({});
  const [dropdownValue, setDropdownValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [vendorSuggestions, setVendorSuggestions] = useState([]);
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setSuggestions([]);
  //   setVendorSuggestions([]);
  // }, []);
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!suggestionSelected) {
        const suggestions = await searchUserByRole("getPls");
        setSuggestions(suggestions);
      } else {
        setSuggestions([]);
      }
    };
    fetchSuggestions();
    setSuggestionSelected(false);
  }, [plName]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!suggestionSelected) {
        const suggestions = await searchUserByRole("getVendors");
        setVendorSuggestions(suggestions);
      } else {
        setVendorSuggestions([]);
      }
    };
    fetchSuggestions();
    setSuggestionSelected(false);
  }, [vendorName]);

  const searchUserByRole = async (url) => {
    const response = await fetchClient(url, {}, { query: searchTerm });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  const selectPlfromList = (suggestion) => {
    setSearchTerm(suggestion.fullName);
    setSuggestions([]);
    setPlName(suggestion);
    setSuggestionSelected(true);
  };

  const selectVendorfromList = (suggestion) => {
    setSearchTerm(suggestion);
    setVendorSuggestions([]);
    setvendorName(suggestion);
    setSuggestionSelected(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const plId = plName.staffId;
      const vendorId = vendorName.staffId;
      const response = await fetchClient(
        `register/user`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fullName,
            staffId,
            email,
            mobile,
            role,
            password,
            plId,
            vendorId,
            contractStartDate,
            contractEndDate,
          }),
        },
        null
      );
      if (response.ok) {
        setMessage(await response.text());
        setMessageType("success");
        setFullName("");
        setStaffId("");
        setEmail("");
        setMobile("");
        setRole("");
        setPassword("");
        setPlName({});
        setvendorName({});
        setSearchTerm("");
        setDropdownValue("");
        setContractStartDate("");
        setContractEndDate("");
      } else {
        setMessage("Failed to add user");
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Failed to add user");
      setMessageType("error");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDropdownChange = (value) => {
    setDropdownValue(value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-md space-y-4"
    >
      <h2 className="text-xl font-bold text-gray-800">Add User</h2>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Full Name:
        </label>
        <input
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Staff Id:
        </label>
        <input
          type="text"
          value={staffId}
          onChange={(e) => setStaffId(e.target.value)}
          required
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Email:
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Mobile:
        </label>
        <input
          type="tel"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Role:</label>
        <select
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
            handleDropdownChange(e.target.value);
          }}
          required
          className="w-full px-3 py-2 border rounded-md"
        >
          <option value="">Select Role</option>
          <option value="ADMIN">Admin</option>
          <option value="PL">Project Leader</option>
          <option value="CP">Contract Programmer</option>
          <option value="VENDOR">Vendor</option>
        </select>
      </div>
      {dropdownValue === "CP" && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Project Leader:
            </label>
            <input
              type="text"
              value={plName.fullName || ""}
              onChange={(e) => {
                setPlName({ ...plName, fullName: e.target.value });
                setSearchTerm(e.target.value);
              }}
              required
              className="w-full px-3 py-2 border rounded-md"
            />
            {suggestions.length > 0 && (
              <ul className="mt-2 border rounded-md bg-white">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() =>
                      suggestion.fullName !== "No PL found with the name" &&
                      selectPlfromList(suggestion)
                    }
                  >
                    {suggestion.fullName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Vendor:
            </label>
            <input
              type="text"
              value={vendorName.fullName || ""}
              onChange={(e) => {
                setvendorName({ ...vendorName, fullName: e.target.value });
                setSearchTerm(e.target.value);
              }}
              required
              className="w-full px-3 py-2 border rounded-md"
            />
            {vendorSuggestions.length > 0 && (
              <ul className="mt-2 border rounded-md bg-white">
                {vendorSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() =>
                      suggestion.fullName !== "No Vendor found with the name" &&
                      selectVendorfromList(suggestion)
                    }
                  >
                    {suggestion.fullName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contract Start Date:
            </label>
            <input
              type="date"
              value={contractStartDate}
              onChange={(e) => setContractStartDate(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contract End Date:
            </label>
            <input
              type="date"
              value={contractEndDate}
              onChange={(e) => setContractEndDate(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-md"
            />
          </div>
        </>
      )}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Temporary Password:
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <Grid item xs={12}>
        {message && <div className={`message ${messageType}`}>{message}</div>}
      </Grid>
      <button
        type="submit"
        className={`w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 ${
          loading ? "opacity-70 cursor-not-allowed" : ""
        }`}
        disabled={loading}
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 mx-auto text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
            ></path>
          </svg>
        ) : (
          "Add User"
        )}
      </button>
    </form>
  );
};

export default AddUser;
